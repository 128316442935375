<template>
  <el-dialog
    title="视频通话"
    class="small-padding"
    :visible.sync="dialogVisible"
    width="910px"
    top="5vh"
    :close-on-click-modal="false"
    @close="hungUp">
    <div class="videoBody">
      <div class="videoContent">
        <div class="title">{{currentCallee}}</div>
        <div class="videoObj">
          <video id="remoteVideo" autoplay></video>
          <video id="localVideo" autoplay></video>
          <i v-if="!connected" class="iconfont iot-video"></i>
        </div>
        <el-button
          v-if="connected"
          id="hungUp"
          round
          type="danger"
          @click="hungUp">
          挂断
        </el-button>
      </div>
      <div v-if="showAlarmList" class="alarmList">
        <div class="alarmTitle" @click="test">呼叫列表</div>
        <div v-if="alarmList.length===0" class="noDateTip">无呼叫请求</div>
        <div v-for="(alarm,index) in alarmList" :key="index" class="alarmItem">
          <span
            class="vm-link"
            :title="'呼叫'+alarm.elevatorName"
            @click="videoCall(alarm.imAccount, alarm.elevatorName)">{{alarm.elevatorName}}</span>
          <span class="alarmTime">{{alarm.alarmTime}}</span>
          <i class="el-icon-circle-close" title="删除该呼叫请求" @click="handleDelete(alarm.mac)"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {mapGetters} from "vuex";
  import huanxinUtil from "../../util/huanxinUtil";

  export default {
    props: {
      alarmList: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        dialogVisible: false,
        connected: false,
        currentCallee: "",
        showAlarmList: true,
        currentCallElevatorName: "",
      };
    },
    computed: {
      ...mapGetters({
        webIMConn: "webIMConn",
      }),
    },
    mounted() {
      this.$bus.$on("openVideoDialog", (callee, elevatorName) => {
        this.showAlarmList = false;
        this.videoCall(callee, elevatorName);
        this.dialogVisible = true;
      });
    },
    methods: {
      open() {
        this.showAlarmList = true;
        this.dialogVisible = true;
        console.log("alarmList: " + this.alarmList);
      },
      hungUp() {
        huanxinUtil.hangup();
      },
      videoCall(account, elevatorName) {
        if (huanxinUtil.isCalling) {
          this.$message.error("您正在通话中");
          return;
        }
        console.log("Video Call:" + elevatorName);
        this.$confirm("确认呼叫【" + elevatorName + "】吗？", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          huanxinUtil.doCalling("video", account, (status) => {
            switch (status) {
              case "hangup":
              case "termCall":
              case "calleeOffline":
              case "onError":
                this.connected = false;
                this.currentCallee = "";
                break;
              case "connected":
                this.currentCallee = "和" + elevatorName + "通话中";
                break;
            }
          });
          this.currentCallee = "正在呼叫【" + elevatorName + "】...";
          this.connected = true;
        });
      },
      test() {
        this.$prompt("请输入对方账号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({value}) => {
          this.videoCall(value, value);
        });
      },
      handleDelete(mac) {
        this.$confirm("确认删除该请求吗", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("elevatorLcds/alarms", mac).then(res => {
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.videoBody {
  display: flex;
  justify-content: center;

  .videoContent {
    text-align: center;
    width: 500px;
    height: 550px;

    .title {
      text-align: center;
      font-size: 18px;
      color: red;
      margin-bottom: 5px;
    }

    .videoObj {
      position: relative;
      height: 500px;
      background-color: #FBFBFB;

      #remoteVideo {
        width: 500px;
        height: 500px;
        background-color: #FBFBFB;
        border: 1px solid #E1E1E1;
      }

      #localVideo {
        width: 130px;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .iot-video {
        font-size: 100px;
        position: absolute;
        color: #999;
        line-height: 500px;
        left: 41%;
        top: 20px;
      }
    }

    #hungUp {
      position: absolute;
      left: 250px;
      bottom: 5px;
    }
  }

  .alarmList {
    flex: 1;
    margin-left: 10px;

    .alarmTitle {
      text-align: center;
      margin-bottom: 10px;
    }

    .noDateTip {
      text-align: center;
      color: #AAA;
      border-bottom: 1px dashed #AAA;
    }

    .alarmItem {
      padding: 2px 2px;
      border-bottom: 1px dashed #AAA;
      display: flex;
      align-items: center;

      .vm-link {
        width: 190px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .alarmTime {
        flex: 1;
        text-align: right;
      }

      .el-icon-circle-close {
        color: #BBB;
        cursor: pointer;
        margin-left: 5px;
        font-size: 15px;

        &:hover {
          color: #777;
        }
      }
    }
  }
}
</style>
