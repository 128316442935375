<template>
  <header class="header">
    <i class="iconfont iot-nav item" @click="toggleSidebar()"></i>
    <div class="title">
      {{ $route.matched[0].meta.title ? $t("route." + $route.matched[0].meta.title) + "/" : "" }}{{
        $t("route."
          + $route.meta.title)
      }}
    </div>
    <span class="center"></span>
    <!--    <el-button-->
    <!--      v-if="$i18n.isCn"-->
    <!--      style="margin-right: 10px;"-->
    <!--      @click="gotoBoard">-->
    <!--      {{$l("headerBar.board", "看板展示")}}-->
    <!--    </el-button>-->
    <el-select v-model="currentComId" style="margin-right: 5px;width: 220px" @change="currentComChange">
      <el-option v-for="item in childrenCom" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <el-select v-model="$i18n.locale" style="width: 100px;margin-right: 10px" @change="saveLang">
      <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-button v-if="!isMute" type="text" style="margin-right: 20px;color: #606266;" @click="setSilence">
      <i class="iconfont iconsound_off" style="font-size: 14px;"></i>
      {{ $l("headerBar.globalMute", "全局静音") }}
    </el-button>
    <el-button v-else type="text" style="margin-right: 20px;color: #606266;" @click="setSilence">
      <i class="iconfont iconsound_off" style="font-size: 14px;"></i>
      {{ $l("headerBar.globalMute", "取消静音") }}
    </el-button>
    <el-popover placement="top-start" style="float: right;margin-right: 10px" trigger="hover">
      <img :src="$tenant.appDownload" width="150px"/>
      <el-button slot="reference" type="text" style="color: #606266;">
        <i class="iconfont icondownapp" style="font-size: 14px;"></i>
        {{ $l("headerBar.app") }}
      </el-button>
    </el-popover>
    <el-dropdown style="margin-right: 5px" @command="handleCommand">
      <el-button>
        <i class="iconfont iconadmin" style="font-size: 14px;"></i>
        {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="setting" align="center">{{ $t("headerBar.setting") }}</el-dropdown-item>
        <el-dropdown-item v-if="currentComId === 3034 || currentComId === 0" divided command="setPort"
          align="center">串口授权</el-dropdown-item>
        <el-dropdown-item divided command="logout" align="center">{{ $t("headerBar.logout") }}</el-dropdown-item>
        <el-dropdown-item divided disabled align="center">{{ version }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <audio id="faultMp3" src="/static/audio/Alarm.mp3"></audio>
    <video-dialog ref="videoDialog" :alarm-list="alarmList"></video-dialog>
  </header>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import * as types from "@/store/mutation-types.js";
import auth from "../../util/auth.js";
import VideoDialog from "./VideoDialog";
import huanxinUtil from "@/util/huanxinUtil";
import { store, mutations } from "@/store/store";

const reconnectInterval = 5000; //

export default {
  components: { VideoDialog },
  data() {
    return {
      ws: null,
      reconnectNum: 3, // 重试次数
      lockReconnect: false, // 避免重复连接
      port: null,
      connectPortTime: 36,//三分钟内无设备自动断开
      username: auth.getUsername(),
      currentComId: parseInt(auth.getCurrentOrgId()),
      childrenCom: [],
      version: "1.0",
      videoStatus: -2,
      alarmList: [],
      hasVideoAuth: this.$auth(407),
      isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      isHttps: /^https:$/.test(window.location.protocol),
      mbPowerLossList: [],
      workerList: [],
      mbBellAlarmList: [],
      deviceStatusList: [],
      isMute: false,
      languages: [{ label: "中文简体", value: "zh-CN" }, { label: "English", value: "en-US" }, { label: "Italian", value: "it-Italy" }],
      notAcceptMaintWork: [],
      recentContract: [],
      recentYearCheck: [],
    };
  },
  computed: {
    ...mapGetters({
      sidebarCollapsed: "sidebarCollapsed",
      webIMConn: "webIMConn",
    }),
    isSilence() {
      return store.isSilence;
    },
  },
  mounted() {
    this.getChildrenCom();
    this.intervalConnect();
    // this.createWs();
    // this.initEventListen();
    if (this.$i18n.isCn && this.hasVideoAuth && this.isChrome && (window.hx_config.isWebRTC || document.domain === "localhost")) {
      this.initIMListen();
      huanxinUtil.init(status => {
        this.videoStatus = status;
      });
    } else { // 不支持视频通话,不进行登录
      this.videoStatus = -1;
    }
  },
  methods: {
    ...mapMutations({
      toggleSidebar: types.TOGGLE_SIDEBAR,
    }),
    setSilence() {
      mutations.setSilence();
      this.isMute = store.isSilence;
    },
    saveLang(lang) {
      window.localStorage.setItem("local", lang);
      location.reload();
    },
    handleCommand(command) {
      switch (command) {
        case "setting":
          this.$router.push({ path: "/account" });
          break;
        case "setPort":
          this.connect();
          break;
        case "logout":
          this.$confirm(this.$t("login.tips.logout"), this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            auth.logout();
            location.reload();
          });
          break;
      }
    },
    async connect() {
      try {
        const requestPort = await navigator.serial.requestPort();
      } catch (error) {
        console.log(error);
      }
    },
    getChildrenCom() {
      this.$axios.get("main/authChildrenOrg").then((res) => {
        this.childrenCom = res.data;
        if (!this.$i18n.isCn) {
          for (let i = 0; i < this.childrenCom.length; i++) {
            if (this.childrenCom[i].name === "G-Cloud电梯云") {
              this.childrenCom[i].name = "G-Cloud";
            }
          }
        }
      }).catch(error => {
        if (error.response) {
          this.$message.error("获取分公司失败，" + error.response.data.message);
        }
      });
    },
    currentComChange(orgId) {
      auth.setCurrentOrgId(orgId);
      this.$router.go(0);
    },
    getAlarmList() {
      if (this.videoStatus === 1 || this.videoStatus === 2) {
        this.$axios.get("elevatorLcds/alarms").then((res) => {
          this.alarmList = res.data;
          if (this.videoStatus === 1 || this.videoStatus === 2) {
            this.videoStatus = this.alarmList.length > 0 ? 2 : 1;
          }
          console.log("获取报警列表成功", this.alarmList.length);
        }).catch(error => {
          if (error.response) {
            this.$message.error("获取报警列表失败，" + error.response.data.message);
          }
        });
      }
    },
    gotoBoard() {
      window.open("/#/kanban");
    },
    initIMListen() {
      setInterval(() => {
        try {
          // this.getAlarmList();
        } catch (err) {
          console.log(err);
        }
      }, 3000);
    },
    initEventListen() {
      setTimeout(() => {
        this.initEvent();
        this.recentExpireEvent();
      }, 2000);
      setInterval(() => {
        this.initEvent();
      }, 10000);
      setInterval(() => {
        this.recentExpireEvent();
      }, 1000 * 60 * 60);
    },
    initEvent() {
      try {
        this.getMbPowerLossList();
        if (this.$auth(302)) {
          this.getWorkerList();
        }
        if (this.$auth(341)) {
          this.getMbBellAlarmList();
        }
      } catch (err) {
        console.log(err);
      }
    },
    recentExpireEvent() {
      try {
        if (this.$auth(322)) {
          this.getNotAcceptMaintWork();
        }
        if (this.$auth(132)) {
          this.getRecentYearCheck();
        }
        if (this.$auth(820)) {
          this.getRecentContract();
        }
      } catch (err) {
        console.log(err);
      }
    },
    getMbPowerLossList() {
      this.$api.getList("mbPowerLoss/notice").then(response => {
        this.showNotice(this.mbPowerLossList, this.$l("workOrder.elevatorGateways", "以下电梯电源系统异常（停电）"), response.data, false, true);
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    getWorkerList() {
      let params = {
        pageIndex: 1,
        workOrderRecordState: 1,
      };
      this.$api.getList("workOrders/web", params).then(response => {
        this.showNotice(this.workerList, this.$l("workOrder.elevatorsRepairs", "以下电梯产生急修"), response.data, false, false);
      });
    },
    getMbBellAlarmList() {
      this.$api.getList("alarmRecords/notice").then(response => {
        this.showNotice(this.mbBellAlarmList, this.$l("workOrder.elevatorAlarm", "以下电梯警铃报警"), response.data, false, true);
      });
    },
    createWs() {
      if (this.ws != null) {
        this.ws.close();
      }
      try {
        this.initEventHandle();
      } catch (e) {
        this.reconnectWs();
      }
    },
    reconnectWs() {
      if (!this.lockReconnect) {
        this.lockReconnect = true;
        // 没连接上会一直重连，设置延迟避免请求过多
        setTimeout(() => {
          if (this.reconnectNum > 0) {
            this.reconnectNum--;
            this.createWs();
            this.lockReconnect = false;
          }
        }, reconnectInterval);
      }
    },
    initEventHandle() {
      // 监听是否连接成功
      this.ws.onopen = () => {
        console.log("ws连接状态：" + this.ws.readyState);
        // 连接成功则发送一个数据
        this.ws.send(auth.getUsername() + "-" + auth.getCurrentOrgId());
        console.log("serve发送数据：" + auth.getUsername() + "-" + auth.getCurrentOrgId());
      },
        // 接听服务器发回的信息并处理展示
        this.ws.onmessage = (event) => {
          try {
            let data = JSON.parse(event.data);
            console.log("接收信息");
            console.log(data);
            if (data.type === 1) {
              this.autoLight();
            }
          } catch (e) {
            console.log(event);
          }
        },
        // 监听连接关闭事件
        this.ws.onclose = () => {
          // 监听整个过程中websocket的状态
          console.log("ws连接状态：" + this.ws.readyState);
        },
        // 监听并处理error事件
        this.ws.onerror = (error) => {
          console.log(error);
          this.reconnectWs();
        };
    },
    intervalConnect() {
      if (this.currentComId === 3034 || this.currentComId === 0) {
        if (this.port === null) {
          let timer = setInterval(() => {
            this.setTimeConnect(timer);
          }, 5000);
        }
      }
    },
    setTimeConnect(timer) {
      setTimeout(() => {
        if (this.connectPortTime > 0) {
          this.connectPortTime--;
          console.log(this.connectPortTime);
          if (this.port) {
            clearInterval(timer);
          } else {
            this.connectPort();
          }
        }
      }, 0);
    },
    async connectPort() {
      if ("serial" in navigator) {
        const ports = await navigator.serial.getPorts();
        console.log("开始搜索串行端口");
        console.log(ports);
        if (ports.length > 0) {
          ports.forEach((item) => {
            console.log("端口");
            console.log(item);
            const dev = item.getInfo();
            console.log("端口详细信息");
            console.log(dev);
            this.port = item;
            this.port.open({
              dataBits: 8, // 数据位
              stopBits: 1, // 停止位
              parity: "none", // 奇偶校验
              baudRate: 9600, // 波特率
            });
            console.log("端口连接成功,");
            console.log(this.port);
            this.createWs();
          });
        } else {
          console.log("暂无授权设备");
        }
      } else {
        console.log("浏览器不支持serial");
      }
    },
    autoLight() {
      this.sendData([65, 72]);
      setTimeout(() => {
        this.sendData([33, 40]);
      }, 10 * 1000);
    },
    sendData(data) {
      let writer = null;
      this.data = data;
      if (this.port) {
        try {
          writer = this.port.writable.getWriter();
          const data = new Uint8Array(this.data);
          writer.write(data);
          writer.releaseLock();
        } catch (error) {
          console.log(error);
        }
      }
    },
    showNotice(cacheIds, title, data, isDeviceStatus, isPageInfo) {
      let list;
      if (isPageInfo) {
        list = data.list;
      } else {
        list = data.records;
      }
      let message = "";
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (!cacheIds.includes(item.id)) {
          if (isDeviceStatus && item.newStatus === 0) {
            message = `${message}${item.elevatorNo}-${item.elevatorName}-离线<br>`;
            cacheIds.push(item.id);
          } else if (isDeviceStatus && item.newStatus === 1) {
            message = `${message}${item.elevatorNo}-${item.elevatorName}-上线<br>`;
            cacheIds.push(item.id);
          } else if (item.sipNo != null) {
            message = `${message}${item.elevatorNo}-${item.elevatorName}-${this.$l("workOrder.extensionNumber", "分机号码：")}${item.sipNo}<br>`;
            cacheIds.push(item.id);
          } else {
            message = `${message}${item.elevatorNo}-${item.elevatorName}<br>`;
            cacheIds.push(item.id);
          }
        }
      }
      if (message) {
        let musicMp3 = document.getElementById("faultMp3");
        if (musicMp3) {
          if (store.isSilence) {
            musicMp3.pause();//关闭播放
          } else {
            musicMp3.play();
          }
        }

        if (this.$i18n.isCn) {
          message = message + "...共" + data.total + "条";
        } else {
          message = message + "...In All " + data.total + " Strip";
        }
        //发出警报
        this.$notify({
          title: title,
          message: message,
          position: "bottom-right",
          type: "warning",
          duration: 15000,
          dangerouslyUseHTMLString: true,
          customClass: "eventNotice",
        });
      }
    },
    toMaintWork() {
      this.$router.push({
        name: "newMaintWorkOrder",
        params: { activeName: "2" },
      }).catch(error => error);
    },
    toContract() {
      this.$router.push({
        name: "contract",
        params: { isOverdue: 2 },
      }).catch(error => error);
    },
    toYearCheck() {
      this.$router.push({
        name: "yearCheckRecord",
        params: { recentOverdue: true },
      }).catch(error => error);
    },
    getNotAcceptMaintWork() {
      let param = {
        recentNotAccept: true,
      };
      this.$http.get("maintenanceOrder", param).then(res => {
        this.showRecentExpireNotice(this.notAcceptMaintWork, this.$l("headerBar.recentMaintWork", "以下电梯维保工作即将到期"), res.data, "maintWork");
      });
    },
    getRecentContract() {
      let param = {
        isOverdue: 2,
      };
      this.$http.get("contract", param).then(res => {
        this.showRecentExpireNotice(this.recentContract, this.$l("headerBar.recentContract", "以下合同即将到期"), res.data, "contract");
      });
    },
    getRecentYearCheck() {
      let today = new Date();
      let year = today.getFullYear() - 1;
      let month = today.getMonth() + 1;
      let day = today.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      let endTime = year + "-" + month + "-" + day;
      let lastMonth = new Date(year, parseInt(month.toString()) - 2, parseInt(day.toString()));
      let lastMonthYear = lastMonth.getFullYear();
      let lastMonthMonth = lastMonth.getMonth() + 1;
      let lastMonthDay = lastMonth.getDate();
      if (lastMonthDay < 10) {
        lastMonthDay = "0" + lastMonthDay;
      }
      if (lastMonthMonth < 10) {
        lastMonthMonth = "0" + lastMonthMonth;
      }
      let startTime = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;
      let param = {
        startTime: startTime + " 00:00:00",
        endTime: endTime + " 23:59:59",
      };
      this.$http.get("yearChecks", param).then(res => {
        this.showRecentExpireNotice(this.recentYearCheck, this.$l("headerBar.recentYearCheck", "以下电梯年检即将到期"), res.data, "yearCheck");
      });
    },
    showRecentExpireNotice(cacheIds, title, data, type) {
      let message = "";
      data.records.forEach((item, index) => {
        if (!cacheIds.includes(item.id)) {
          cacheIds.push(item.id);
          if (index < 10) {
            if (type === "contract") {
              message = `${message}${item.contractNo}-${item.useUnitName}<br>`;
            } else {
              message = `${message}${item.elevatorNo}-${item.elevatorName}<br>`;
            }
          }
        }
      });
      if (message) {
        if (this.$i18n.isCn) {
          message = message + "...共" + data.total + "条  点击跳转";
        } else {
          message = message + "...In All " + data.total + " Strip";
        }
        let notify = this.$notify({
          title: title,
          message: message,
          position: "bottom-right",
          type: "warning",
          duration: 15000,
          dangerouslyUseHTMLString: true,
          customClass: "eventNotice",
          onClick: () => {
            if (type === "yearCheck") {
              this.toYearCheck();
            } else if (type === "maintWork") {
              this.toMaintWork();
            } else if (type === "contract") {
              this.toContract();
            }
            notify.close();
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";

.header {
  display: flex;
  align-items: center;
  height: $header-height;

  .title {
    color: #4d4d4d;
    font-size: 12px;
  }

  .center {
    flex: 1;
  }

  .item {
    margin: 0 5px 0 15px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      color: $color-primary;
    }
  }

  .video-connecting {
    background-color: #E6A23C;
    border-color: #E6A23C;
  }

  .video-new-call {
    animation: videoBtn 0.3s infinite;
  }

  .video-connect-failed {
    background-color: #f25e43;
    border-color: #f25e43;
  }

  @keyframes videoBtn {
    0% {
      background: red;
    }

    50% {
      background: yellow;
      color: dimgray;
    }

    100% {
      background: red;
    }
  }

  .el-dropdown {
    .el-button {
      background: none;
      border: none;
    }
  }
}
</style>
<style lang="scss">
.eventNotice.el-notification {
  width: 400px;
}

.header {
  .el-input__inner {
    border-radius: 8px !important;
  }
}
</style>
