import Main from "@/views/main/Main.vue";
// @formatter:off
// 不作为Main组件的子页面展示的页面单独写，如下
export const otherRouter = [
  {path: "/dataManagement", name: "dataManagement", component: () => import("@/views/document/DataManagement.vue")},
  {path: "/login", name: "login", component: () => import("@/views/main/Login.vue")},
  {path: "/pdf", name: "pdf", component: () => import("@/views/document/PdfReader.vue")},
  {path: "/china", name: "china", component: () => import("@/views/main/China.vue")},
  {path: "/malaysia", name: "malaysia", component: () => import("@/views/main/Malaysia.vue")},
  {path: "/thirdLogin/:username,:password", name: "thirdLogin", component: () => import("@/views/main/ThirdLogin.vue")},
  {path: "/cloud", name: "cloud", component: () => import("@/views/cloud/Cloud.vue")},
  {path: "/kanban", name: "kanban", component: () => import("@/views/KanBan/KanBan.vue")},
  {path: "/eocdOperate", name: "eocdOperate", component: () => import("@/views/elevator/EocdOperate.vue")},
  {path: "/private", name: "private", component: () => import("@/views/sysItem/Private.vue")},
  {path: "/h5video", name: "h5video", component: () => import("@/views/cloud/H5Video1")},
  {path: "/genGzReport", name: "genGzReport", component: () => import("@/views/cloud/GenGzReport")},
  {path: "/scheme/:id", name: "scheme", component: () => import("@/views/modSalesOrder/SchemeOrderPreview.vue")},
  {
    path: "/singleMonitor/:elevatorId",
    name: "singleMonitor",
    component: () => import("@/views/monitor/SingleMonitor.vue"),
  },
  {
    path: "/singleMonitorOld/:elevatorId",
    name: "singleMonitorOld",
    component: () => import("@/views/monitor/SingleMonitorOld.vue"),
  },
  {
    path: "/MarkToUseUnit/:useUnitId,:useUnitName",
    name: "MarkToUseUnit",
    component: () => import("@/views/monitor/MarkToUseUnit.vue"),
  },
  {
    path: "/monitorMore/:useUnitId,:buildingNum,:useUnitName",
    name: "monitorMore",
    component: () => import("@/views/monitor/MonitorMore.vue"),
  },
  {
    path: "/elevatorMonitor/monitorCenter",
    name: "monitorCenter",
    component: () => import("@/views/monitor/MonitorCenter.vue"),
  },
  {
    path: "/elevatorTest/:elevatorId",
    name: "elevatorTest",
    component: () => import("@/views/elevator/ElevatorTest.vue"),
  },
  {
    path: "/elevatorCheck/:elevatorId",
    name: "elevatorCheck",
    component: () => import("@/views/elevator/ElevatorCheck.vue"),
  },
  {path: "/elevator/:elevatorId", name: "elevatorQuery", component: () => import("@/views/elevator/ElevatorQuery.vue")},
  {
    path: "/workOrder/:workOrderId",
    name: "workOrderQuery",
    component: () => import("@/views/workOrder/WorkOrderQuery.vue"),
  },
  {
    path: "/dayControl/:id",
    name: "dayControlPreview",
    component: () => import("@/views/dayWeekMonthManger/dayControl/dayControlPreview.vue"),
  },
  {
    path: "/dayControlReport/:id",
    name: "dayControlReport",
    component: () => import("@/views/dayWeekMonthManger/dayControl/dayControlReport.vue"),
  },
  {
    path: "/weekCheck/:id",
    name: "weekCheckPreview",
    component: () => import("@/views/dayWeekMonthManger/weekCheck/weekCheckPreview.vue"),
  },
  {
    path: "/echartReport/:id/:type",
    name: "echartReport",
    component: () => import("@/views/dayWeekMonthManger/report/EchartReport.vue"),
  },
  {
    path: "/monthDispatch/:id",
    name: "monthDispatchPreview",
    component: () => import("@/views/dayWeekMonthManger/monthDispatch/monthDispatchPreview.vue"),
  },
  {path: "/jx/imageList", name: "JxImageList", component: () => import("@/views/workOrder/ImageList.vue")},
  {
    path: "/maintWorkOrder/:maintWorkOrderId",
    name: "maintWorkOrderQuery",
    component: () => import("@/views/maintWorkOrder/MaintWorkOrderQuery.vue"),
  },
  {
    path: "/newMaintWorkOrder/:maintWorkOrderId",
    name: "newMaintWorkOrderQuery",
    component: () => import("@/views/newMaintWorkOrder/MaintWorkOrderQuery"),
  },
  {
    path: "/elevator/report/:elevatorId",
    name: "elevatorStatsReport",
    component: () => import("@/views/elevator/ElevatorStatsReport"),
  },
  {path: "/simCard/:iccid", name: "simCard", component: () => import("@/views/device/simCard.vue")},
  {
    path: "/monitorReport/:id/",
    name: "monitorReport",
    component: () => import("@/views/passport/guangzhou/MonitorReport.vue"),
  },
  {
    path: "/8NMuztq/:workOrderId",
    name: "workOrderH5",
    component: () => import("@/views/workOrder/WorkOrderH5.vue"),
  },
  {
    path: "/h5workOrder/:workOrderId",
    name: "workOrderH5",
    component: () => import("@/views/workOrder/WorkOrderH5.vue"),
  },
];

// 作为Main组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里
export const mainRouterNotInMenu = {
  path: "/",
  component: Main,
  children: [
    {path: "/", name: "home", meta: {title: "home"}, component: () => import("@/views/main/Home.vue")},
    {path: "account", name: "account", meta: {title: "account"}, component: () => import("@/views/user/Account.vue")},
    {
      path: "/publicNoticeMore",
      name: "publicNoticeMore",
      meta: {title: "notice"},
      component: () => import("@/views/publicNotice/PublicNoticeMore.vue"),
    },
    {
      path: "/publicNoticeDetail/:id",
      name: "publicNoticeDetail",
      meta: {title: "noticeDetail"},
      component: () => import("@/views/publicNotice/publicNoticeDetail.vue"),
    },
  ],
};

// 作为Main组件的子页面展示并且在左侧菜单显示的路由写在appRouter里
export const mainRouterInMenu = [
  {
    path: "/bigDataManager",
    meta: {title: "bigDataManager", icon: "iot-system"},
    component: Main,
    children: [
      {
        path: "/dayWeekMonthManger",
        name: "dayWeekMonthManger",
        meta: {title: "dayWeekMonthManger", authIndex: 823, onlyCnShow: true},
        component: () => import("@/views/dayWeekMonthManger/ManagerChoiceList"),
      },
      // {
      //   path: "/aiManger",
      //   name: "aiManger",
      //   meta: {title: "aiManger", authIndex: 823, onlyCnShow: true},
      //   component: () => import("@/views/bigDataManger/AiManagerList"),
      // }
    ],
  },
  // {
  //   path: "/dayWeekMonthManger",
  //   meta: {title: "dayWeekMonthManger", icon: "iot-system"},
  //   component: Main,
  //   children: [
  //     {
  //       path: "/dayControl",
  //       name: "dayControl",
  //       meta: {title: "dayControl", authIndex: 823, onlyCnShow: true},
  //       component: () => import("@/views/dayWeekMonthManger/dayControl/dayControlList"),
  //     },
  //     {
  //       path: "/weekCheck",
  //       name: "weekCheck",
  //       meta: {title: "weekCheck", authIndex: 823, onlyCnShow: true},
  //       component: () => import("@/views/dayWeekMonthManger/weekCheck/weekCheckList"),
  //     },
  //     {
  //       path: "/monthDispatch",
  //       name: "monthDispatch",
  //       meta: {title: "monthDispatch", authIndex: 823, onlyCnShow: true},
  //       component: () => import("@/views/dayWeekMonthManger/monthDispatch/monthDispatchList"),
  //     },
  //     {
  //       path: "/checkTemplates",
  //       name: "checkTemplates",
  //       meta: {title: "checkTemplates", authIndex: 826, onlyCnShow: true},
  //       component: () => import("@/views/dayWeekMonthManger/checkTemplates/checkTemplatesList"),
  //     },
  //   ],
  // },
  {
    path: "/elevatorInfo",
    meta: {title: "elevator", icon: "iot-elevator"},
    component: Main,
    children: [
      {
        path: "/elevatorNetApply",
        name: "elevatorNetApply",
        meta: {title: "elevatorNetApply", authIndex: 814},
        component: () => import("@/views/elevatorNetApply/List.vue"),
      },
      {
        path: "/elevator",
        name: "elevator",
        meta: {title: "elevatorInfo", authIndex: 110},
        component: () => import("@/views/elevator/ElevatorList.vue"),
      },
      {
        path: "/device",
        name: "device",
        meta: {title: "device", authIndex: 9},
        component: () => import("@/views/device/DeviceSelectList.vue"),
      },
      // {
      //   path: "/potentialFault",
      //   name: "potentialFault",
      //   meta: {title: "danger", authIndex: 701, onlyCnShow: true},
      //   component: () => import("@/views/potentialFault/PotentialFaultElevatorList.vue"),
      // },
      {
        path: "/vmVideo",
        name: "vmVideo",
        meta: {title: "vmVideo", onlyCnShow: true, authIndex: 813},
        component: () => import("@/views/vmVideo/TPLinkVideoList"),
      },
      {
        path: "/smartScreen",
        name: "smartScreen",
        meta: {title: "smartScreen", authIndexes: [71, 73]},
        component: () => import("@/views/smartScreen/SmartScreenList"),
      },
      {
        path: "/elevatorSetting",
        name: "elevatorSetting",
        meta: {title: "elevatorSetting", authIndexes: [20, 24, 109]},
        component: () => import("@/views/elevator/elevatorSetting/ElevatorSettingList"),
      },
    ],
  },
  {
    path: "/elevatorMonitor",
    meta: {title: "monitor", icon: "iot-monitor"},
    component: Main,
    children: [
      {
        path: "/elevatorMonitor/gisMap",
        name: "gisMap",
        meta: {title: "map", authIndex: 404},
        component: () => import("@/views/monitor/GisMap.vue"),
      },
      {
        path: "/elevatorMonitor/singleLadderMonitor",
        name: "singleLadderMonitor",
        meta: {title: "singleMonitor", authIndex: 403},
        component: () => import("@/views/monitor/singleLadderMonitor.vue"),
      },
      {
        path: "/elevatorMonitor/useUnitMonitor",
        name: "useUnitMonitor",
        meta: {title: "useUnitMonitor", authIndex: 401},
        component: () => import("@/views/monitor/UseUnitMonitor.vue"),
      },
      // {
      //   path: "/elevatorMonitor/videoMonitor",
      //   name: "videoMonitor",
      //   meta: {title: "videoMonitor", authIndex: 405, onlyCnShow: true},
      //   component: () => import("@/views/monitor/VideoMonitor.vue"),
      // },
      //COP 视频通话 先隐藏
      // {
      //   path: "/videoCall",
      //   name: "videoCall",
      //   meta: {title: "videoCall", authIndex: 407, onlyCnShow: true},
      //   component: () => import("@/views/videoCall/VideoCallList.vue"),
      // },
      {
        path: "/elevatorMonitor/monitorCenter",
        name: "monitorCenterUrl",
        meta: {title: "monitorCenter", newPage: true, authIndex: 406, onlyCnShow: true},
      },
      {
        path: "/kanban",
        name: "kanban",
        meta: {title: "kanban", newPage: true, authIndex: 406, onlyCnShow: true},
      },
    ],
  },
  {
    path: "/maint",
    meta: {title: "event", icon: "iconeventmng"},
    component: Main,
    children: [
      {
        path: "/event",
        name: "mb",
        meta: {title: "mb", authIndex: 45},
        component: () => import("@/views/event/EventList.vue"),
      },
      {
        path: "/powerLoss",
        name: "mbPowerLoss",
        meta: {title: "mbPowerLoss", authIndex: 45},
        component: () => import("@/views/event/MbPowerLossList.vue"),
      },
      {
        path: "/alarmBell",
        name: "alarmRecord",
        meta: {title: "alarmRecords", authIndex: 341},
        component: () => import("@/views/event/AlarmRecordList.vue"),
      },
      {
        path: "/fault",
        name: "faultRecord",
        meta: {title: "faultRecords", authIndex: 117},
        component: () => import("@/views/event/FaultRecordList.vue"),
      },
      {
        path: "/potential",
        name: "eocdPotential",
        meta: {title: "eocdPotential", authIndex: 821},
        component: () => import("@/views/event/EocdPotentialList"),
      },
      {
        path: "/userTake",
        name: "userTakeRecord",
        meta: {title: "userTakeRecord", authIndex: 38, onlyCnShow: true},
        component: () => import("@/views/event/UserTakeRecordList.vue"),
      },
    ],
  },
  {
    path: "/maint",
    meta: {title: "upkeep", icon: "iot-repair"},
    component: Main,
    children: [
      {
        path: "/contract",
        name: "contract",
        meta: {title: "contract", authIndex: 820},
        component: () => import("@/views/contract/ContractList"),
      },
      {
        path: "/workOrder",
        name: "workOrder",
        meta: {title: "repairJob", authIndex: 302},
        component: () => import("@/views/workOrder/WorkOrderList.vue"),
      },
      {
        path: "/maintWork",
        name: "newMaintWorkOrder",
        meta: {title: "maintenanceWorkOrder", authIndex: 322},
        component: () => import("@/views/newMaintWorkOrder/MaintWorkOrderList.vue"),
      },
      // {
      //   path: "/maintWorkOrder",
      //   name: "maintWorkOrder",
      //   meta: {title: "maintenanceWorkOrder", authIndex: 312},
      //   component: () => import("@/views/maintWorkOrder/MaintWorkOrderList.vue"),
      // },
      {
        path: "/yearCheck",
        name: "yearCheckRecord",
        meta: {title: "annualInspectionRecord", authIndex: 132},
        component: () => import("@/views/yearCheck/YearCheckList.vue"),
      },
      {
        path: "/safeCheck",
        name: "safeCheckOrder",
        meta: {title: "safeCheckOrder", authIndex: 827, onlyCnShow: true},
        component: () => import("@/views/safeCheckOrder/SafeCheckOrderList.vue"),
      },
      {
        path: "/modSalesOrder",
        name: "modSalesOrder",
        meta: {title: "modSalesOrder", authIndex: 838, onlyCnShow: true},
        component: () => import("@/views/modSalesOrder/MODSalesOrderList.vue"),
      },
      {
        path: "/maintenanceSetting",
        name: "maintenanceSetting",
        meta: {title: "maintenanceSetting", authIndexes: [326, 327, 328]},
        component: () => import("@/views/newMaintWorkOrder/maintSetting/maintBaseInfoList.vue"),
      },
    ],
  },
  {
    path: "/baseInfo",
    meta: {title: "baseInfo", icon: "iot-basic-info"},
    component: Main,
    children: [
      // {
      //   path: "/partsType",
      //   name: "partsType",
      //   meta: {title: "partsType", authIndex: 807, onlyCnShow: true},
      //   component: () => import("@/views/partsType/PartsTypeList.vue"),
      // },
      {
        path: "/useUnitCom",
        name: "useUnitCom",
        meta: {title: "useUnitCom", authIndex: 16},
        component: () => import("@/views/company/useUnitCom/UseUnitComList"),
      },
      {
        path: "/createCompany",
        name: "createCompany",
        meta: {title: "createCompany", authIndex: 12},
        component: () => import("@/views/company/createCom/CreateCompanyList"),
      },
      {
        path: "/propertyCompany",
        name: "propertyCompany",
        meta: {title: "propertyCompany", authIndex: 12},
        component: () => import("@/views/company/propertyCom/PropertyCompanyList"),
      },
      {
        path: "/maintCompany",
        name: "maintCompany",
        meta: {title: "maintCompany", authIndex: 12},
        component: () => import("@/views/company/maintCom/MaintCompanyList"),
      },
      {
        path: "/installCompany",
        name: "installCompany",
        meta: {title: "installCompany", authIndex: 12},
        component: () => import("@/views/company/installCom/InstallCompanyList"),
      },
      {
        path: "/changeCompany",
        name: "changeCompany",
        meta: {title: "changeCompany", authIndex: 12},
        component: () => import("@/views/company/changeCom/ChangeCompanyList"),
      },
      {
        path: "/quantity",
        name: "quantity",
        meta: {title: "qualitySupervision", onlyCnShow: true},
        component: () => import("@/views/quantity/QuantityList.vue"),
      },
      // {
      //   path: "/floorDisplay",
      //   name: "floorDisplay",
      //   meta: {title: "floorDisplay", authIndex: 24},
      //   component: () => import("@/views/floorDisplay/OldFloorDisplayList"),
      // },
      // {
      //   path: "/oldFaults",
      //   name: "fault",
      //   meta: {title: "faultTemplate", authIndex: 20},
      //   component: () => import("@/views/fault/FaultList"),
      // },
      {
        path: "/parts",
        name: "parts",
        meta: {title: "parts", authIndex: 806},
        component: () => import("@/views/parts/PartsList.vue"),
      },
      {
        path: "/monitorDevice",
        name: "monitorDevice",
        meta: {title: "monitorDevice", authIndex: 812, onlyCnShow: true},
        component: () => import("@/views/monitorDevice/monitorDeviceList.vue"),
      },
      // {
      //   path: "/userThirdPart",
      //   name: "userThirdPart",
      //   meta: {title: "videoUser", authIndex: 71, onlyCnShow: true},
      //   component: () => import("@/views/userThirdPart/UserThirdPartList.vue"),
      // },
    ],
  },
  {
    path: "/userRole",
    meta: {title: "userRole", icon: "iot-user"},
    component: Main,
    children: [
      {
        path: "/user",
        name: "user",
        meta: {title: "user", authIndex: 3},
        component: () => import("@/views/user/UserList.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {title: "role", authIndex: 5},
        component: () => import("@/views/role/RoleList.vue"),
      },
    ],
  },
  {
    path: "/system",
    meta: {title: "system", icon: "iot-system"},
    component: Main,
    children: [
      {
        path: "/newMsgSend",
        name: "newMsgSend",
        meta: {title: "newMsgSend", authIndex: 816},
        component: () => import("@/views/system/MsgSendList"),
      },
      {
        path: "/userLoginLog",
        name: "userLoginLog",
        meta: {title: "userLoginLog", authIndex: 817},
        component: () => import("@/views/system/UserLoginLogList"),
      },
      {
        path: "/userOperateLog",
        name: "userOperateLog",
        meta: {title: "userOperateLog", authIndex: 818},
        component: () => import("@/views/system/UserOperateLogList"),
      },
      {
        path: "/systemSetting",
        name: "systemSetting",
        meta: {title: "systemSetting", authIndex: 801},
        component: () => import("@/views/system/SystemSettingEdit.vue"),
      },
    ],
  },
  {
    path: "/document ",
    meta: {title: "document", icon: "iconeventmng"},
    component: Main,
    children: [
      {
        path: "/pan",
        name: "product",
        meta: {title: "product", authIndex: 830, onlyCnShow: true},
        component: () => import("@/views/document/PanList.vue"),
      },
      {
        path: "/video",
        name: "video",
        meta: {title: "video", authIndex: 832, onlyCnShow: true},
        component: () => import("@/views/document/VideoList.vue"),
      },
      {
        path: "/banner",
        name: "banner",
        meta: {title: "banner", authIndex: 834, onlyCnShow: true},
        component: () => import("@/views/document/BannerList.vue"),
      },
      {
        path: "/fileManage",
        name: "fileManage",
        meta: {title: "fileManage", authIndex: 841, onlyCnShow: true},
        component: () => import("@/views/document/FileManageList.vue"),
      },
    ],
  },
  {
    path: "/maint",
    meta: {title: "accessPlatform", icon: "iot-service"},
    component: Main,
    children: [
      {
        path: "/shanghai",
        name: "shanghai",
        meta: {title: "shanghai", authIndex: 42, onlyCnShow: true},
        component: () => import("@/views/passport/ShanghaiList.vue"),
      },
      {
        path: "/huzhou",
        name: "huzhou",
        meta: {title: "huzhou", authIndex: 52, onlyCnShow: true},
        component: () => import("@/views/passport/HuZhouList"),
      },
      {
        path: "/chongqing",
        name: "chongqing",
        meta: {title: "chongqing", authIndex: 48, onlyCnShow: true},
        component: () => import("@/views/passport/chongqing/Chongqing.vue"),
      },
      {
        path: "/guangzhou",
        name: "guangzhou",
        meta: {title: "guangzhou", authIndex: 62, onlyCnShow: true},
        component: () => import("@/views/passport/guangzhou/GuangzhouSelectList.vue"),
      },
      {
        path: "/liaoning",
        name: "liaoning",
        meta: {title: "liaoning", authIndex: 51, onlyCnShow: true},
        component: () => import("@/views/passport/LiaoningList"),
      },
      {
        path: "/shenyang",
        name: "shenyang",
        meta: {title: "shenyang", authIndex: 49, onlyCnShow: true},
        component: () => import("@/views/passport/ShenyangList.vue"),
      },
      {
        path: "/fujian",
        name: "fujian",
        meta: {title: "fujian", authIndex: 58, onlyCnShow: true},
        component: () => import("@/views/passport/fujian/FujianList"),
      },
      {
        path: "/jiaxing",
        name: "jiaxing",
        meta: {title: "jiaxing", authIndex: 50, onlyCnShow: true},
        component: () => import("@/views/passport/jiaxing/JiaxingList"),
      },
      {
        path: "/quzhou",
        name: "quzhou",
        meta: {title: "quzhou", authIndex: 50, onlyCnShow: true},
        component: () => import("@/views/passport/QuzhouList"),
      },
      {
        path: "/guangxi",
        name: "guangxi",
        meta: {title: "guangxi", authIndex: 59, onlyCnShow: true},
        component: () => import("@/views/passport/guangxi/GuangxiList"),
      },
      {
        path: "/zhejiang",
        name: "zhejiang",
        meta: {title: "zhejiang", authIndex: 60, onlyCnShow: true},
        component: () => import("@/views/passport/zhejiang/ZhejiangList"),
      },
      {
        path: "/nanchang",
        name: "nanchang",
        meta: {title: "nanchang", authIndex: 55, onlyCnShow: true},
        component: () => import("@/views/passport/NanchangList"),
      },
      {
        path: "/shaoxing",
        name: "shaoxing",
        meta: {title: "shaoxing", authIndex: 53, onlyCnShow: true},
        component: () => import("@/views/passport/ShaoxingList"),
      },
      {
        path: "/ningbo",
        name: "ningbo",
        meta: {title: "ningbo", authIndex: 54, onlyCnShow: true},
        component: () => import("@/views/passport/ningbo/NingboList"),
      },
      {
        path: "/hangzhou",
        name: "hangzhou",
        meta: {title: "hangzhou", authIndex: 43, onlyCnShow: true},
        component: () => import("@/views/passport/HangzhouList"),
      },
      {
        path: "/volks",
        name: "volks",
        meta: {title: "volks", authIndex: 61, onlyCnShow: true},
        component: () => import("@/views/passport/VolksList.vue"),
      },
      // {
      //   path: "/wuxi",
      //   name: "wuxi",
      //   meta: {title: "wuxi", authIndex: 44, onlyCnShow: true},
      //   component: () => import("@/views/passport/wuxi96333/WuxiList.vue"),
      // },
    ],
  },
  {
    path: "/maint",
    meta: {title: "Operations", icon: "iot-statistics"},
    component: Main,
    children: [
      {
        path: "/integration",
        name: "integration",
        meta: {title: "integration", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/integration/List"),
      },
      {
        path: "/deviceGateway",
        name: "deviceGateway",
        meta: {title: "deviceGateway", onlySuperAdmin: true},
        component: () => import("@/views/deviceGateway/DeviceChooseList"),
      },
      {
        path: "/uiSetting",
        name: "uiSetting",
        meta: {title: "uiSetting", onlySuperAdmin: true},
        component: () => import("@/views/system/UiSettingList.vue"),
      },
      {
        path: "/sysItem",
        name: "sysItem",
        meta: {title: "sysItem", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/sysItem/SysltemTab.vue"),
      },
      {
        path: "/homeUser",
        name: "homeUser",
        meta: {title: "homeUser", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/homeUser/HomeUserList.vue"),
      },
      {
        path: "/msgTemplate",
        name: "msgTemplate",
        meta: {title: "msgTemplate", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/msgTemplate/MsgTemplateList.vue"),
      },
      {
        path: "/developer",
        name: "developer",
        meta: {title: "developerPermission", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/developer/DeveloperList.vue"),
      },
      {
        path: "/ztVersion",
        name: "ztVersion",
        meta: {title: "ztVersion", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/ztVersion/ZTVersionList.vue"),
      },
      {
        path: "/tenant",
        name: "tenant",
        meta: {title: "tenant", onlySuperAdmin: true},
        component: () => import("@/views/tenant/SettingList"),
      },
      // {
      //   path: "/company",
      //   name: "company",
      //   meta: {title: "company", onlySuperAdmin: true, onlyCnShow: true},
      //   component: () => import("@/views/company/CompanyList.vue"),
      // },
      // {
      //   path: "/publicNotice",
      //   name: "publicNotice",
      //   meta: {title: "notice", onlySuperAdmin: true, onlyCnShow: true},
      //   component: () => import("@/views/publicNotice/PublicNoticeList.vue"),
      // },
      {
        path: "/potentialFaultSetting",
        name: "potentialFaultSetting",
        meta: {title: "potentialFaultSetting", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/potentialFault/PotentialFaultSettingList"),
      },
    ],
  },
];

// 所有上面定义的路由都要写在下面的routers里
export const routers = [...otherRouter, ...mainRouterInMenu, mainRouterNotInMenu];
