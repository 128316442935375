import Vue from "vue";
import Router from "vue-router";
import { routers } from "./router";
import auth from "../util/auth";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式

Vue.use(Router);
// 定义路由
export const router = new Router({
  routes: routers,
});

const whiteUris = ["/login"]; // 白名单,无需登录即可访问

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (
    to.path.indexOf("/cloud") !== -1 ||
    to.path.indexOf("/dataManagement") !== -1 ||
    to.path.indexOf("/eocdOperate") !== -1 ||
    to.path.indexOf("/monitorReport") !== -1 ||
    to.path.indexOf("/8NMuztq") !== -1 ||
    to.path.indexOf("/h5workOrder") !== -1 ||
    to.path.indexOf("/h5video") !== -1 ||
    to.path.indexOf("/genGzReport") !== -1 ||
    to.path.indexOf("/pdf") !== -1 ||
    to.path.indexOf("/private") !== -1 ||
    to.path.indexOf("/thirdLogin") !== -1 ||
    to.path.indexOf("/scheme") !== -1 ||
    whiteUris.indexOf(to.path) !== -1 ||
    auth.isLogin()
  ) {
    // 在白名单当中或者已经登录,放行
    next();
  } else {
    toLogin(to.fullPath);
  }
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});

export let toLogin = (redirectUrl) => {
  auth.logout();
  window.location.href = "#login";
  window.location.reload();
};

export default router;
